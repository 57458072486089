import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Typography: FC<PropsWithChildren<{
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  color?: string;
}>> = (props) => {
  const { variant, color, children } = props;

  const Element = typography[variant ?? 'p'];

  return <Element color={color}>{children}</Element>;
};

export const H1 = styled.h1`
  font-size: 32px;
  line-height: 48px;
  color: ${({ color }) => color};
`;

export const H2 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  color: ${({ color }) => color};
`;

export const H3 = styled.h3`
  font-size: 18px;
  line-height: 27px;
  color: ${({ color }) => color};
`;

export const H4 = styled.h4`
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => color};
`;

export const H5 = styled.h5`
  font-size: 14px;
  line-height: 21px;
  color: ${({ color }) => color};
`;

export const H6 = styled.h6`
  font-size: 12px;
  line-height: 18px;
  color: ${({ color }) => color};
`;

export const P = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: ${({ color }) => color};
`;
export const typography = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: P,
};

export default Typography;
