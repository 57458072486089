import { Box, Button } from '@adminjs/design-system';
import styled from 'styled-components';

import { colors, defaultRadius } from '../../branding';

export const WelcomeScreenContainer = styled(Box)`
  margin: auto 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${defaultRadius};
  max-width: 750px;
  min-height: 400px;
  width: 100%;
  display: flex;
  box-shadow: ${colors.containerShadow};
`;

export const WelcomeImage = styled(Box)`
  background-image: url("/public/assets/activate-account/laptop.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  flex: 2;
  background-color: ${colors.sidebar.background};
  border-radius: ${defaultRadius};
`;

export const WelcomeContent = styled(Box)`
  width: 100%;
  height: auto;
  flex: 3;
  padding: 4rem;
`;

export const Wrapper = styled(Box)`
  text-align: center;
`;

export const Logo = styled.img`
  height: 2rem;
  width: 100%;
  margin: auto;
`;

export const InfoIcon = styled.img`
  height: 5rem;
  width: auto;
  margin: 2rem auto 0;
`;

export const WelcomeText = styled(Box)`
  text-align: center;
  max-width: 350px;
  margin: auto;
  padding: 1rem;

  > p {
    font-size: 1rem;
  }
`;

export const Actions = styled(Box)`
  display: flex;
  justify-content: flex-end;
  button {
    cursor: pointer;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const Form = styled.form`
  input {
    width: 100%;
  }
`;

export const HeaderText = styled.span`
  display: block;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

export const DescriptionText = styled.span`
  display: block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0.5rem 0 0;
`;

export const BackButton = styled(Button)`
  background-color: #fff;
  color: #212121;
  border-color: #bbc3cb;
  border-radius: 4px;

  :hover {
    background-color: #fff;
    color: #212121;
    border-color: #bbc3cb;
    border-radius: 4px;
  }
`;
