import React, { FC } from 'react';
import { Button as AdminButton, ButtonProps } from '@adminjs/design-system';
import styled from 'styled-components';

import { defaultRadius } from '../branding';

export const Button: FC<ButtonProps> = (props) => <StyledButton {...props} />;

const StyledButton = styled(AdminButton)`
  border-radius: ${defaultRadius};
`;

export default Button;
