import { ValidationError } from 'class-validator';
import { PropertyErrors } from 'adminjs';

export const validationsToAdminErrors = (
  validationErrors: ValidationError[]
): PropertyErrors => {
  if(!validationErrors?.length) {
    return {
      generalError: {
        message: 'Unexpected error happened. Try again later',
      },
    };
  }

  return validationErrors.reduce(
    (adminErrors: PropertyErrors, error) => ({
      ...adminErrors,
      [error.property]: {
        type: 'error',
        message:
          error.constraints?.[Object.keys(error.constraints)[0]] ??
          'Invalid value',
      },
    }),
    {}
  );
};
