import React, { FC } from 'react';
import { combineStyles } from '@adminjs/design-system';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { branding } from '../../../branding';
import Login from '../pages/Login';
import ActivateAccount from '../pages/ActivateAccount';
import RequestPasswordResetConfirmation from '../pages/RequestPasswordResetConfirmation';
import RequestPasswordReset from '../pages/RequestPasswordReset';
import ResetPasswordConfirmation from '../pages/ResetPasswordConfirmation';
import ResetPassword from '../pages/ResetPassword';

import Layout from './Layout';

const App: FC = () => {
  const theme = combineStyles(branding.theme);

  return (
    <>
      <Helmet>
        <title>Scalerr</title>
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="/public/assets/favicon.ico"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Layout>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/activate-account"
                element={<ActivateAccount />}
              />
              <Route
                path="/request-password-reset-confirmation"
                element={<RequestPasswordResetConfirmation />}
              />
              <Route
                path="/request-password-reset"
                element={<RequestPasswordReset />}
              />
              <Route
                path="/reset-password-confirmation"
                element={<ResetPasswordConfirmation />}
              />
              <Route
                path="/reset-password"
                element={<ResetPassword />}
              />
            </Routes>
          </BrowserRouter>
        </Layout>
      </ThemeProvider>
    </>
  );
};

export default App;
