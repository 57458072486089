/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC } from 'react';
import { themeGet } from '@adminjs/design-system';
import { ResourceJSON } from 'adminjs';

import { branding } from '../../../branding';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import { useAccountActivation } from '../hooks/useAccountActivation';
import {
  Actions,
  WelcomeScreenContainer,
  WelcomeContent,
  WelcomeImage,
  BoldText,
  Form,
  Logo,
  WelcomeText,
} from '../welcome-screen-components';
import { useQuery } from '../hooks/useQuery';

const ActivateAccount: FC = () => {
  const { token, email } = useQuery<{ token: string, email: string }>();
  const {
    onChange,
    fields,
    record,
    onSubmit,
    isLoading,
  } = useAccountActivation({
    token,
  });

  return (
    <WelcomeScreenContainer>
      <WelcomeImage />
      <WelcomeContent>
        <Logo src={branding.logo as string} />
        <WelcomeText>
          <Typography>
            Welcome to Scalerr.{' '}
            {email && (
              <span>
                Complete your account for{' '}
                <BoldText data-testid="email-in-active-account">
                  {email}
                </BoldText>
                .
              </span>
            )}
          </Typography>
          {record.errors.generalError && (
            <Typography color={themeGet('colors', 'error')}>
              {record.errors.generalError.message}
            </Typography>
          )}
        </WelcomeText>
        <Form onSubmit={onSubmit}>
          {fields.map((field) => (
            <field.Component
              key={field.path}
              where="edit"
              resource={{} as ResourceJSON}
              record={record}
              onChange={onChange}
              property={field}
            />
          ))}
          <Actions>
            <Button variant="primary" size="lg" disabled={isLoading}>
              Create Account
            </Button>
          </Actions>
        </Form>
      </WelcomeContent>
    </WelcomeScreenContainer>
  );
};

export default ActivateAccount;
