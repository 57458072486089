import { FormEvent, useState } from 'react';
import type {
  BasePropertyProps,
  ErrorMessage,
  PropertyJSON,
  RecordJSON,
} from 'adminjs';
import BaseTextInput from 'adminjs/lib/frontend/components/property-type/default-type/edit';
import BasePasswordInput from 'adminjs/lib/frontend/components/property-type/password/edit';
import { ValidationError } from 'class-validator';

import { users } from '../api';
import { validationsToAdminErrors } from '../../../utils/admin-validation.utils';
import { emptyRecord } from '../../../components/utils/emptyRecord';

export type UserInputs = {
  firstName?: string;
  lastName?: string;
  password?: string;
};

export const useAccountActivation = ({
  token,
}: {
  token: string;
}): {
  onChange: (fieldName: string, value: string) => void;
  onSubmit: (e: FormEvent) => Promise<void>;
  record: RecordJSON;
  isLoading: boolean;
  fields: (PropertyJSON & {
    Component: React.ComponentType<BasePropertyProps>;
  })[];
} => {
  const [inputs, setInputs] = useState<UserInputs>({});
  const [errors, setErrors] = useState<Record<string, ErrorMessage>>({});
  const [isLoading, setLoading] = useState(false);

  const onChange = (fieldName: string, value: string): void => {
    clearFieldError(fieldName);
    setInputs((currentInputs) => ({ ...currentInputs, [fieldName]: value }));
  };

  const clearFieldError = (fieldName: string): void => {
    setErrors((previousErrors) => {
      delete previousErrors[fieldName];

      return previousErrors;
    });
  };

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    setErrors({});
    try {
      await users().activate({
        ...inputs,
        token,
      });
      window.location.href = '/';
    } catch (e) {
      if (e.response?.status === 400) {
        setErrors(validationsToAdminErrors(e.response.data.message as ValidationError[]));
      } else if (e.response?.data?.message ?? e.message) {
        setErrors({ generalError: e.response?.data?.message ?? e.message });
      } else {
        setErrors({
          generalError: {
            message: 'Unexpected error happened. Try again later',
          },
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const record = {
    ...emptyRecord,
    params: inputs,
    errors,
  } as RecordJSON;
  const fields = [
    {
      Component: BaseTextInput,
      label: 'First Name',
      isRequired: true,
      propertyPath: 'firstName',
      path: 'firstName',
    },
    {
      Component: BaseTextInput,
      label: 'Last Name',
      isRequired: true,
      propertyPath: 'lastName',
      path: 'lastName',
    },
    {
      Component: BasePasswordInput,
      label: 'Password',
      isRequired: true,
      propertyPath: 'password',
      path: 'password',
      type: 'password',
    },
  ] as (PropertyJSON & { Component: React.ComponentType<BasePropertyProps> })[];

  return { onChange, record, fields, onSubmit, isLoading };
};
