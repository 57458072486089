import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { branding } from '../../../branding';
import { PATHS } from '../constants';
import {
  Logo,
  InfoIcon,
  WelcomeContent,
  Wrapper,
  WelcomeImage,
  WelcomeScreenContainer,
  WelcomeText,
  HeaderText,
  DescriptionText,
  BackButton,
} from '../welcome-screen-components';

const ResetPasswordConfirmation: FC = () => {
  const navigate = useNavigate();

  return (
    <WelcomeScreenContainer>
      <WelcomeImage />
      <WelcomeContent>
        <Wrapper>
          <Logo src={branding.logo as string} />
          <InfoIcon src="/public/icons/success2-icon.svg" />
          <WelcomeText>
            <HeaderText>Success</HeaderText>
            <DescriptionText>It worked! You have successfully set a new password.</DescriptionText>
          </WelcomeText>
          <BackButton size="lg" onClick={() => navigate(PATHS.LOGIN)} mr={16}>
              Back to Sign in
          </BackButton>
        </Wrapper>
      </WelcomeContent>
    </WelcomeScreenContainer>
  );
};

export default ResetPasswordConfirmation;
