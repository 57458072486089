import { RecordJSON } from 'adminjs';

export const emptyRecord: RecordJSON = {
  id: '',
  title: '',
  baseError: null,
  errors: {},
  params: {},
  populated: {},
  bulkActions: [],
  recordActions: [],
};
