/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC } from 'react';
import { Box, themeGet } from '@adminjs/design-system';
import { ResourceJSON } from 'adminjs';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { branding } from '../../../branding';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import { useLogin } from '../hooks/useLogin';
import { PATHS } from '../constants';
import {
  Actions,
  Form,
  Logo,
  WelcomeContent,
  WelcomeImage,
  WelcomeScreenContainer,
  WelcomeText,
} from '../welcome-screen-components';

const Login: FC = () => {
  const { onChange, fields, record, onSubmit, isLoading } = useLogin();
  const navigate = useNavigate();

  return (
    <>
      <WelcomeScreenContainer>
        <WelcomeImage />
        <WelcomeContent>
          <Logo src={branding.logo as string} />
          <WelcomeText>
            {record.errors.generalError && (
              <Typography color={themeGet('colors', 'error')}>
                {record.errors.generalError.message}
              </Typography>
            )}
          </WelcomeText>
          <Form onSubmit={onSubmit}>
            {fields.map((field) => (
              <field.Component
                key={field.path}
                where="edit"
                resource={{} as ResourceJSON}
                record={record}
                onChange={onChange}
                property={field}
              />
            ))}
            <ForgotPasswordContainer>
              <ForgotPasswordButton
                variant="text"
                color="error"
                type="button"
                onClick={() => navigate(PATHS.REQUEST_PASSWORD_RESET)}
              >
                Forgot your password?
              </ForgotPasswordButton>
            </ForgotPasswordContainer>
            <Actions>
              <Button variant="primary" size="lg" disabled={isLoading}>
                Sign In
              </Button>
            </Actions>
          </Form>
        </WelcomeContent>
      </WelcomeScreenContainer>
    </>
  );
};

const ForgotPasswordButton = styled(Button)`
  font-size: 12px;
  padding: 0;
`;
const ForgotPasswordContainer = styled(Box)`
  display: grid;
  place-items: flex-end;
  margin-bottom: 12px;
  margin-top: -42px;
`;

export default Login;
