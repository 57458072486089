/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { VFC } from 'react';
import { themeGet } from '@adminjs/design-system';
import { ResourceJSON } from 'adminjs';

import { branding } from '../../../branding';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import { useRequestPasswordReset } from '../hooks/useRequestPasswordReset';
import {
  Actions,
  Form,
  Logo,
  InfoIcon,
  WelcomeContent,
  Wrapper,
  WelcomeImage,
  WelcomeScreenContainer,
  WelcomeText,
  HeaderText,
  DescriptionText,
  BackButton,
} from '../welcome-screen-components';

const RequestPasswordReset: VFC = () => {
  const { onChange, fields, record, onSubmit, isLoading, onCancel } =
    useRequestPasswordReset();

  return (
    <WelcomeScreenContainer>
      <WelcomeImage />
      <WelcomeContent>
        <Wrapper>
          <Logo src={branding.logo as string} />
          <InfoIcon src="/public/icons/reset-password-icon.svg" />
          <WelcomeText>
            <HeaderText>Reset password</HeaderText>
            <DescriptionText>Enter your email to get password reset instructions</DescriptionText>
            {record.errors.generalError && (
              <Typography color={themeGet('colors', 'error')}>
                {record.errors.generalError.message}
              </Typography>
            )}
          </WelcomeText>
        </Wrapper>
        <Form onSubmit={onSubmit}>
          {fields.map((field) => (
            <field.Component
              key={field.path}
              where="edit"
              resource={{} as ResourceJSON}
              record={record}
              onChange={onChange}
              property={field}
            />
          ))}
          <Actions>
            <BackButton size="lg" onClick={onCancel} mr={16}>
              Back
            </BackButton>
            <Button variant="primary" size="lg" disabled={isLoading}>
              Submit
            </Button>
          </Actions>
        </Form>
      </WelcomeContent>
    </WelcomeScreenContainer>
  );
};

export default RequestPasswordReset;
