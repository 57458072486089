import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = <T extends Record<string, string>>(): Partial<T> => {
  const { search } = useLocation();

  return useMemo(() => {
    const entries = new URLSearchParams(search).entries();
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }

    return result as Partial<T>;
  }, [search]);
};
