import axios, { AxiosInstance, AxiosResponse } from 'axios';

export const API = (): AxiosInstance =>
  axios.create({ baseURL: process.env.BACKEND_URL });

export const users = (): {
  activate: (data: ActivationData) => Promise<AxiosResponse>;
  resetPassword: (data: ResetPasswordData) => Promise<AxiosResponse>;
  requestPasswordReset: (data: RequestResetPasswordData) => Promise<AxiosResponse>;
  login: (data: LoginData) => Promise<AxiosResponse>;
} => {
  const usersRoute = '/users';

  return {
    activate: (data) => API().post(`${usersRoute}/activate`, data),
    resetPassword: (data) => API().post(`${usersRoute}/reset-password`, data),
    requestPasswordReset: (data) => API().post(`${usersRoute}/request-password-reset`, data),
    login: ({ email, password }) => {
      const form = new FormData();
      form.set('email', email);
      form.set('password', password);

      return API().post('login', form);
    },
  };
};

export type ActivationData = {
  firstName?: string;
  lastName?: string;
  password?: string;
  token?: string;
};

export type LoginData = {
  email?: string;
  password?: string;
};

export type ResetPasswordData = {
  token?: string;
  password?: string;
};

export type RequestResetPasswordData = {
  email?: string;
};
