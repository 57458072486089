import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@adminjs/design-system';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, #app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
`;

const Wrapper = styled(Box)`
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
`;
const Layout: FC<PropsWithChildren> = ({ children }) => (
  <Wrapper flex bg="grey20">
    <GlobalStyle />
    {children}
  </Wrapper>
);

export default Layout;
