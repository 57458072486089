import { BrandingOptions } from 'adminjs';

export const branding: BrandingOptions = {
  companyName: 'Scalerr',
  logo: '/public/assets/logo.svg',
  favicon: '/public/assets/favicon.ico',
  theme: {
    colors: {
      primary100: '#ED1A3B',
      primary20: 'rgba(237, 26, 59, 0.1)',
      accent: '#022B52',
      grey60: '#717171',
      bg: '#f8f9f9',
      dropzoneBorder: '#bbc3cb',
      border: '#bbc3cb',
      error: '#C20012',
      success: '#007D7F',
      info: '#0047B6',
      filterBg: '#212121',
      filterInputBorder: '#bbc3cb',
    },
  },
};

export const colors = {
  panelBackground: '#f8f9f9',
  emptyStateDescription: '#717171',
  dropZoneBackground: '#fbfbfb',
  caption: '#717171',
  sidebar: {
    background: '#212121',
    border: 'rgba(255,255,255,0.1)',
    label: '#717171',
    text: '#eeeeef',
  },
  sectionCustomizer: {
    modalTitle: '#1d2329',
    toggleBackground: '#eeeeef',
    drawerBackground: '#fbfbfb',
  },
  titleHeader: '#212121',
  text: '#212121',
  dottedCardBoarder: '#bbc3cb',
  cardShadow: 'rgba(12,30,41,0.1)',
  containerShadow: '0 24px 40px 0 rgba(13, 30, 41, 0.2)',
  cardImageBorder: '#eeeeef',
  tileBackground: '#ffffff',
  infoBackground: 'rgba(255,189,86,0.15)',
  successBackground: 'rgba(40, 167, 69, 0.25)',
  nonOptimized: '#a14f17',
};

export const defaultRadius = '4px';
export const defaultContainerRadius = '4px';
